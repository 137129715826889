.site-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #fff; /* Background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

.navbar-nav .nav-link.active {
  font-weight: bold;
  color: #5ebb00; /* Active link color */
}

.dropdown-menu {
  min-width: 200px; /* Optional width for dropdown */
}
