.carousel-arrow {
    font-size: 1.5rem; /* Adjust size of the arrow icons */
}

.carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.carousel-control-prev {
    left: 10px; /* Adjust position if necessary */
}

.carousel-control-next {
    right: 10px; /* Adjust position if necessary */
}
