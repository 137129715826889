
.chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s ease;
  }
  
  .chat-button:hover {
    background-color: #0056b3;
  }
  
  .chat-open {
    transform: rotate(180deg);
  }
  
  .chat-window {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 350px;
    height: 400px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    z-index: 1000;
  }
  
  .chat-header {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .chat-header h5 {
    margin: 0;
    font-size: 1rem;
  }
  
  .chat-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  
  .chat-message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    max-width: 80%;
    word-wrap: break-word;
  }
  
  .chat-message.user {
    align-self: flex-end;
    background-color: #f0f0f0;
  }
  
  .chat-message.bot {
    align-self: flex-start;
    background-color: #e1f5fe;
  }
  
  .chat-footer {
    padding: 10px;
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
  }
  
  .chat-footer input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-right: 10px;
    outline: none;
  }
  
  .chat-footer button {
    background-color: "";
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .chat-footer button:hover {
    background-color: "";
  }
  