.home-container {
  background-color: #ffffff;
  padding: 20px;
}

.hero-section {
  background: url('https://www.avis.fr/content/dam/avis/avis/fr-fr/images/hero_background.jpg') no-repeat center center;
  background-size: cover;
  color: white;
  padding: 100px 0;
}

.promo-section {
  background-color: white;
  padding: 50px 0;
  margin: 20px 0;
  border-radius: 10px;
}

.monthly-rental {
  background-color: #f1f1f1;
  padding: 50px 0;
  text-align: center;
}

