
.nav-link.active, .nav-link.active-link {
    background-color: #009b46;
    color: white !important;
}

.nav-link.active-link {
    font-weight: bold;
}



/* Styles pour les sous-menus */
.submenu {
    list-style-type: none; 
    padding: 0;
    margin: 0; 
    padding-left: 20px; 
    display: none; 
}

.submenu li {
    margin-bottom: 5px; /* Espacement entre les éléments du sous-menu */
}

.submenu a {
    text-decoration: none; /* Supprime le soulignement des liens */
    color: #333; /* Couleur du texte des liens */
    display: block; /* Affiche les liens en tant que blocs pour une meilleure cliquabilité */
    padding: 5px 10px; /* Ajoute du padding autour des liens */
}

.submenu a:hover {
    background-color: #f0f0f0; /* Change le fond lors du survol */
    color: #007bff; /* Change la couleur du texte lors du survol */
}

/* Styles pour l'animation de l'ouverture des sous-menus */
.collapse-enter, .collapse-exit {
    transition: height 0.3s ease;
}

.collapse-enter-active, .collapse-exit-active {
    height: auto;
}

.collapse-exit {
    height: 0;
}
