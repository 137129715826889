/* ForgotPasswordPage.css */
.mt-120 {
    margin-top: 120px;
}

.text-center {
    text-align: center;
}

.text-center .form-control {
    display: inline-block;
    width: 50px;
    margin: 0 5px;
    font-size: 2rem;
    text-align: center;
}