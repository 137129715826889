.payment-option {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    background-color: #f8f9fa;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
    text-align: center;
    border: 2px solid transparent; /* Par défaut, pas de bordure */
}

.payment-option:hover {
    background-color: #e9ecef;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.payment-option.selected {
    border: 2px solid #007bff; /* Bordure bleue pour l'option sélectionnée */
    background-color: #dfeeff;
    box-shadow: 0px 4px 6px rgba(0, 0, 255, 0.2);
}

.payment-icon {
    color: #007bff;
}
