.benefits-section {
    background: url('../../../../public/cheque-reservation.jpg') no-repeat center center fixed; /* Background image */
    background-size: cover; /* Cover the entire section */
    color: white; /* Text color */
    padding: 100px 0; /* Padding around the section */
    text-align: center; /* Center the text */
    position: relative; /* Position for overlay */
}

.benefits-section::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 1; /* Ensure overlay is above background */
}

.title {
    font-size: 3rem; /* Larger font for prominence */
    font-weight: bold;
    margin-bottom: 20px; /* Spacing below title */
    z-index: 2; /* Ensure title is above overlay */
    position: relative; /* Position for z-index */
}

.description {
    font-size: 1.5rem; /* Increased font size for better readability */
    margin-bottom: 40px; /* Spacing below description */
    z-index: 2; /* Ensure description is above overlay */
    position: relative; /* Position for z-index */
}

.benefit {
    margin: 20px 0; /* Spacing for each benefit */
    z-index: 2; /* Ensure benefit items are above overlay */
    position: relative; /* Position for z-index */
}

.benefit-number {
    font-size: 3rem; /* Size for statistics */
    font-weight: bold; /* Bold font for statistics */
}

.benefit p {
    font-size: 1.2rem; /* Size for descriptions */
}
