.about-page {
    padding: 40px 0;
}

.about-page h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.about-page h3 {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 20px;
}

.card {
    transition: transform 0.2s;
}
