.reservation-detail {
    border-radius: 4px;
    padding: 1rem;
}

.vehicle-images {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.vehicle-image {
    max-width: 200px;
    max-height: 150px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.status {
    font-weight: bold;
}

