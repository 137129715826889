/* CarListFilterList.css */

/* Ajoute un peu d'espace autour du formulaire de filtres */
form.mb-4 {
  padding: 10px;
  background-color: #f8f9fa; /* Couleur de fond légèrement grise */
  border-radius: 8px; /* Coins arrondis */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

/* Styles pour les cartes de véhicule */
.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


/* Pour ajuster la pagination */
.pagination {
  margin-top: 20px;
}

.pagination-item {
  cursor: pointer;
}

/* Cacher les filtres sur mobile */
.d-none.d-lg-block {
  display: none !important;
}

@media (min-width: 992px) {
  .d-none.d-lg-block {
    display: block !important;
  }
}
