.property-details {
  padding: 20px;
}

.property-details .owner-info {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.property-details .owner-info img.owner-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 15px;
}
